import {doRequest} from "@services";
import {serviceConstants} from "@utils/apiService";

class fetchCmsService {

    getCmsDetails() {
        
        // Lala
        return serviceConstants.getCmsData();
        
        // for api direct code
       // return doRequest(serviceConstants.getCmsData())
       // end
    }

}

const FetchCmsServiceInstance = new fetchCmsService();
export default FetchCmsServiceInstance;
