import FetchCmsServiceInstance from "../FetchCms/service"
 import {ACTION} from "../FetchCms/constants";

export const getCmsDetails = () => {
    console.log("CMS response Data21 :- ")
    return dispatch => {
        return FetchCmsServiceInstance.getCmsDetails().then(function (response) { 
            console.log("CMS response Data2 :- " + response)
            dispatch({
                type: ACTION.GET_FETCH_CMS_DETAILS,
                apiResponse: response,
                 
            }); 
            console.log("CMS response Data :- " + response)
            return response;
            
        }).catch((error) => { 
            dispatch({
                type: ACTION.GET_FETCH_CMS_DETAILS,
                apiResponse: error,
            });
            console.log("CMS error Data :- " + error)
        });
    }
};

 